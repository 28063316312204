export const LocalStorage = {
  get(key) {
    let stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : undefined;
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  },
};
