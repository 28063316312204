import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getCookie } from "../utils/utilFunction";

const OpenRoute = ({ children }) => {
  let location = useLocation();
  const { id, user_referral } = useParams();
  const token = getCookie("persistent_auth");
  if (token) {
    if (
      location.pathname === `/shared-challenge/${id}/${user_referral}/intro` ||
      location.pathname === `/shared-challenge/${id}/${user_referral}/login`
    ) {
      return (
        <Navigate
          to={`/shared-challenge/${id}/${user_referral}/process`}
          state={{ from: location }}
          replace
        />
      );
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default OpenRoute;
