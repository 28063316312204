import React, { useEffect } from "react";
import './App.scss';
import { BrowserRouter } from "react-router-dom";
import RouterElement from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import ToastComponent from "./components/Common/ToastComponent";
import { setOrgDetails, setToast, setUser } from "./redux/userSlice";
import { errorMsgWork, getCookie } from "./utils/utilFunction";
import { orgInfoApi, userInfo } from "./services/api.service";

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <AppLayout />
          </BrowserRouter>
          <ToastComponent />
        </PersistGate>
      </div>
    </Provider>
  );
}

const AppLayout = () => {
  const dispatch = useDispatch();
  const token = getCookie("persistent_auth");
  const { orgDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (orgDetails?.name) document.title = orgDetails?.name
  }, [orgDetails]);

  useEffect(() => {
    if (token) {
      getUserData();
    } else {
      getOrgInfo();
    }
  }, []);

  useEffect(() => {
    const fetchApiData = async () => {
      if (orgDetails) {
        document.documentElement.style.setProperty('--navbar', orgDetails?.navbar);
        document.documentElement.style.setProperty('--primary', orgDetails?.primary_btn);
        document.documentElement.style.setProperty('--primary-link', orgDetails?.primary_link);
        document.documentElement.style.setProperty('--primary-background', orgDetails?.background);
        document.documentElement.style.setProperty('--secondary', orgDetails?.secondary);
        document.documentElement.style.setProperty('--org_primary', orgDetails?.org_primary);
      }
    };

    fetchApiData();
  }, []);

  const getUserData = async () => {
    try {
      const userData = await userInfo()
      dispatch(setUser({ ...userData?.data }));
      dispatch(setOrgDetails(userData?.data?.organization));
      updateColors(userData?.data?.organization)
    } catch ({ response }) {
      dispatch(setToast({ msg: errorMsgWork(response) }));
    }
  };

  const getOrgInfo = async () => {
    try {
      await orgInfoApi().then((res) => {
        dispatch(setOrgDetails(res?.data));
        updateColors(res?.data)
      });
    } catch (error) { }
  };
  const updateColors = (data) => {

  }

  return (
    <>
      <RouterElement />
    </>
  );
};


export default App;
