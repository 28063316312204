import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getCookie } from "../utils/utilFunction";

const ProtectedRoute = ({ children }) => {
  let location = useLocation();
  const { id, user_referral } = useParams();
  const token = getCookie("persistent_auth");
  if (token) {
    return children;
  } else {
    if (
      location.pathname === `/shared-challenge/${id}/${user_referral}/process`
    ) {
      return (
        <Navigate
          to={`/shared-challenge/${id}/${user_referral}/intro`}
          state={{ from: location }}
          replace
        />
      );
    }
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
