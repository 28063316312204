import axios from "axios";
import store from "../store/store.js";
import { refreshAccessToken, setToast, signOut } from "../redux/userSlice.js";
import { UNAUTHORIZED_STATUS, INTERNAL_SERVER_ERROR } from "../utils/constants";
import { getCookie } from "../utils/utilFunction.js";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "",
  headers: {
    "Content-Type": "application/json",
    "API-KEY": ""
  },
});

api.interceptors.request.use(
  (config) => {
    // Get the token from the store
    const token = getCookie("persistent_auth");

    // Attach the token to the Authentication header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const { response, config } = error;
    // Check if the error status is 401 (unauthorized)
    if (response && response?.status === UNAUTHORIZED_STATUS) {
      if (error?.response?.data?.code === "user_inactive") {
        store.dispatch(signOut());
        return Promise.reject(error);
      }
      const { refresh } = store.getState()?.user;
      if (!config.url.includes("token", "signup", "login") && refresh) {
        try {
          // Refresh the JWT token
          const refreshTokenResponse = await api.post("api/token/refresh/", { refresh: refresh });
          if (refreshTokenResponse?.data) {
            store.dispatch(refreshAccessToken(refreshTokenResponse.data));
            // Retry the failed request with the new token
            const originalRequest = { ...config };
            originalRequest.headers.Authorization = `Bearer ${refreshTokenResponse.data.access}`;
            // Resolves the promise if successful
            return api(originalRequest);
          }
        } catch ({ response }) {
          // Handle refresh token failure (e.g., logout the user)
          if (response && response?.status === UNAUTHORIZED_STATUS) {
            signOutFunc();
          }
        }
      } else {
        signOutFunc();
        return Promise.reject(error);
      }
    } else if (error.code === "ERR_NETWORK" || error.message === "Network Error") {
      // console.error(error);
      return Promise.reject("Having some network trouble, please try again after sometimes.");
    } else if (error.code === "ERR_BAD_RESPONSE" || error.code === INTERNAL_SERVER_ERROR) {
      // console.error(error);
      return Promise.reject(error);
    } else {
      // For other errors, simply reject the promise
      return Promise.reject(error);
    }
  }
);

const signOutFunc = () => {
  store.dispatch(signOut());
  store.dispatch(setToast({ msg: "You have been logged out." }));
}

export default api;